require.context('../img', true);

window.addEventListener('load', () => {
    const body              = document.getElementsByTagName('body')[0];
    const header            = document.getElementById('header');
    const menuBtn           = document.getElementById('menu-btn');
    const messageButton     = document.getElementById('message-button');
    const messageWrapper    = document.getElementById('message-wrapper');
    const notificationClose = document.getElementById('notification-close');
    const notificationBlock = document.getElementById('notification');
    const mainBlock         = document.getElementById('main');

    if (notificationClose) {
        notificationClose.addEventListener('click', () => {
            notificationBlock.classList.add('notification__hidden');
            mainBlock.classList.add('main__top');
        });
    }

    messageButton.addEventListener('click', () => {
        messageButton.classList.toggle('message__button_active');
        messageWrapper.classList.toggle('message__wrapper_socials');
    });

    if (window.scrollY > 50) {
        header.classList.add('header__scroll');
    } else {
        header.classList.remove('header__scroll');
    }
    var scrollTimer = null;

    window.addEventListener('scroll', () => {
        if (window.scrollY > 50) {
            header.classList.add('header__scroll');
        } else {
            header.classList.remove('header__scroll');
        }

        clearTimeout(scrollTimer);
        messageWrapper.classList.add('message__wrapper_animation');
        scrollTimer = setTimeout(function () {
            messageWrapper.classList.remove('message__wrapper_animation');
        }, 200);

    });

    $('[data-scroll]').on('click', function (event) {
        event.preventDefault();
        let $this       = $(this),
            blockId     = $this.data('scroll'),
            blockOffset = $(blockId).offset().top;
        menuBtn.classList.remove('menu__btn_close');
        header.classList.remove('menu__mob');
        body.classList.remove('overflow');

        $('html, body').animate(
            {
                scrollTop: blockOffset - 100,
            },
            500,
        );
    });


    menuBtn.addEventListener('click', () => {
        menuBtn.classList.toggle('menu__btn_close');
        header.classList.toggle('menu__mob');
        body.classList.toggle('overflow');

    });


    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);


    let titleMask = gsap.utils.toArray('.title__mask_text');
    titleMask.forEach((item) => {
        gsap.from(item, {
            y: '100%',
            duration: 1,
            ease: 'power3.out',
            scrollTrigger: {
                trigger: item,
                start: 'top 100%',
            },
        });
    });

    let hash = window.location.hash;
    if (hash && $(hash).length) {
        $('html, body').animate({
            scrollTop: $(hash).offset().top,
        }, 500);
    }

    $('.lang.link').on('click', function () {
        const locale = $(this).data('locale');

        setCookie(window.locale_cookie_name, locale, {'max-age': window.locale_cookie_max_age});
    });
});

function setCookie(name, value, options = {}) {
    options = {path: '/', ...options};

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let key in options) {
        updatedCookie += '; ' + key;

        let value = options[key];

        if (value !== true) {
            updatedCookie += '=' + value;
        }
    }

    document.cookie = updatedCookie;
}
